@keyframes pop {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.01);
    }

    100% {
        transform: scale(1.03);
    }
}

.about__section-image {
    border-radius: 5rem;
    overflow: hidden;
    transition: var(--transition); 
    transform: scale(1);
    transition: transform 0.5s ease-in-out;      
    background: black;
}
.about__section2-image {
    border-radius: 5rem;
    overflow: hidden;
    transition: var(--transition);  
    transform: scale(1);
    transition: transform 0.5s ease-in-out;    
    background: black;    
}
.about__section3-image {
    border-radius: 5rem;
    overflow: hidden;
    transition: var(--transition);
    transform: scale(1);
    transition: transform 0.5s ease-in-out;  
    background: black;
          
}

.about__section-image img {
    opacity: 0.7;  
}
.about__section2-image img {
    opacity: 0.7;  
}

.about__section3-image img {
    opacity: 0.7;  
}



.about__section-image:hover {
    transform: scale(1.03);
}
.about__section2-image:hover {
    transform: scale(1.03);
}
.about__section3-image:hover {
    transform: scale(1.03);
}

.about__section-content h1 {
    margin-bottom: 2rem;
}

.about__section-content p {
    margin-bottom: 1rem;
}

.about__story-container {
    display: grid;
    grid-template-columns: 30% 40%;
    gap:10%;
}

.about__section-image img {
    height: 100%;
    scale: 100%;
}
.about__section2-image img {
    height: 100%;
}
.about__section3-image img {
    height: 100%;
}

.about__mission-container {
    display: grid;
    grid-template-columns: 30% 40%;
    gap:10%;
}

.about__Vision-container {
    display: grid;
    grid-template-columns: 30% 50%;
    gap:10%;
}

.about__section-content p {
    font-size: 17.5;
}



/*MEDUIUM SCREEN*/

@media screen and (max-width: 1024px) {
    .about__section2-image, .about__section3-image   {
        width:90%;
        margin-inline: auto;
    }
    .about__section-image {
        height: 40rem;
        width:90%;
        margin-inline: auto;
    }
    .about__section-image img{
        object-fit: cover; /* Ensure the image covers the container */
        object-position: top; /* Positio*/
        
    }

    .about__section-content {
        margin-bottom: 1.2rem;
    }

    .about__story-container, .about__mission-container, .about__Vision-container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .about__Vision .about__section2-image {
        grid-row: 1;
        
    }
}

/*SMall Screens*/
@media screen and (max-width: 600px) {
    .about__section-image {
        height: 20rem;
        width: 80%; 
    }

    .about__story-container, .about__mission-container, .about__Vision-container {

        gap: 1.7rem;
    }

}