#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
    
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    
  }
  
  .swiper {
    width: 100%;
    height: 100%;
    
    scale: 0.85;

  }
  
  .swiper-slide {
    margin-top: 10rem;
    text-align: center;
    font-size: 18px;
    background: transparent;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 77%;
    border: 0.4rem solid rgb(95, 95, 95);

    transition: var(--transition2);
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: saturate(0);
    transition: var(--transition2); 
  }

  .swiper-slide img:hover {
    box-shadow: 0 1rem 1rem rgba(0,0,0,0.4);
    filter: saturate(1);
  }

  .swiper-pagination-bullet {
    background-color: var(--color-primary) !important;
  }

  .KR_container {
    margin-bottom: -11rem;
    margin-top: -2rem;
  }

  .HomeReno_container h1 {
    font-family: "impact";
    color:black;
    text-shadow: 1px 0 0 white, 0 -1px 0 white, 0 1px 0 white, -1px 0 0 white;
  }

  .HomeReno_container {
    margin-bottom: -11rem;
    margin-top: -3rem;
  }

  .KR_container h1 {
    font-family: "impact";
    color:black;
    text-shadow: 1px 0 0 white, 0 -1px 0 white, 0 1px 0 white, -1px 0 0 white;
  }

  .BR_container {
    margin-bottom: -11rem;
    margin-top: -3rem;
  }

  .BR_container h1 {
    font-family: "impact";
    color:black;
    text-shadow: 1px 0 0 white, 0 -1px 0 white, 0 1px 0 white, -1px 0 0 white;
  }

  .BC_container {
    margin-bottom: -11rem;
    margin-top: -3rem;
  }

  .BC_container h1 {
    font-family: "impact";
    color:black;
    text-shadow: 1px 0 0 white, 0 -1px 0 white, 0 1px 0 white, -1px 0 0 white;
  }






  @media screen and (max-width:1024px) {

    .swiper-slide img {
      filter: saturate(1)
    }

    .swiper-slide img:hover {
      box-shadow: none;

    }

  }
