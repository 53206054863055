*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --primary-hue: 210;
    --gray-hue: 240;
    --color-primary: #FFC83D;
    --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
    --color-gray-200: hsl(var(--gray-hue), 26%, 75%);
    --color-gray-300: hsl(var(--gray-hue), 24%, 64%);
    --color-gray-400: hsl(var(--gray-hue), 16%, 41%);
    --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
    --color-gray-600: hsl(var(--gray-hue), 48%, 65%);
   
    --container-width-lg: 80%;
    --container-width-md: 90%;

    --transition: all 500ms ease; 
    --transition2: all 400ms ease; 
}


/*Some general styles*/

body {
    font-family: 'Montserrat', sans-serif;
    color: var(--color-gray-200);
    line-height: 1.7;
    overflow-x: hidden;
    background: rgb(103, 103, 103);
    background: linear-gradient(
    0deg,
    rgba(103, 103, 103, 1) 40%,
    rgb(187, 189, 188) 100%
    );
    background-attachment: fixed;
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

h1, h2, h3, h4, h5 {
    line-height: 1.2;
    color: var(--color-gray-100);
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
}

a {
    color: white;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

.btn {
    color: rgb(255, 255, 255);
    width: fit-content;
    font-weight: 500;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--color-primary);
    border-radius: 1.5rem;
    transition: var(--transition);
}

p {
    color: rgb(229, 229, 229);
    text-shadow: 1px 0 0 rgb(62, 61, 61), 0 -1px 0 rgb(62, 61, 61), 0 1px 0 rgb(62, 61, 61), -1px 0 0 rgb(62, 61, 61);
    font-weight:500;
    font-family:'Lato';
    font-size: 16;    
}

.btn:hover {
    background: #ffe8ad;
    color: black;
}

.btn.lg {
    padding: 1.2rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
}

.btn.sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
}

.btn.sm:hover {
    background: white;
    font-weight: 500;
}


section {
    margin-top: 10rem;
}

.section__head {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: -2rem;
}

.section__head span {
 background:#FFC83D;
 padding: 0.8rem;
 border-radius: 1rem;
 color: white;
 font-size: 1.6rem;
}

.card {
    background: transparent;
    border: 2px solid transparent;
    text-align: center;
    padding: 1rem 1.5rem;
    border-radius: 0 3rem 0 3rem;
    transition: var(--transition);
}

.card:hover {
    background: transparent;
    cursor: default;
    scale:1.1;
}

.card span{
    width: 90%;
    height: 17rem;


    color:rgb(170, 170, 170);
    
    padding: 0.5rem;
    display: grid;
    
    place-items: center;
    margin-bottom: 1.5rem;
    margin-inline: auto;
    border-radius: 1rem;
    transition: var(--transition);
}

.card span img {
    border-radius: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
    height: 20rem;
    
}

.card:hover span img {
    transition: var(--transition);
    border-color: white;
    opacity: 0.8;
}


.header {
    margin-top:5rem;
    height:35rem;
    overflow: hidden;
    border-bottom: 2px solid #FFC83D;
}

.header__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
    background: black;
}

.header__container-bg
{
    position: absolute;
    width: 100%;
    
}

.header__container-bg img {
    opacity: 0.5;
}

.header__content {
    position: relative;
    width:44%;
    margin: 0 auto;
    text-align: center;
    color: white;
}

.header__content h2 {
    margin-bottom: 1rem;
}

.header__content p {
    color: #cacaca;
}

.contact-header .header {
    height: 10rem;
}




/* MEDIA QUERIES (Medium Screens) */

@media screen and (max-width:1024px) {
    .container {
        width: var(--container-width-md);

    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.6rem;
    }

    .header__content {
        width:76%;
    }
}

@media screen and (max-width:600px) {
    h1 {
        font-size: 2.2rem;
        line-height: 1.3;
    }

    section {
        margin-top: 7rem;
    }

    .header {
        height: fit-content;
    }

    .header__content {
        width: var(--container-width-md);
        padding: 3rem 0;
    }

    .header__content p {
        font-size: 0.75rem;
    }


}