@keyframes pop {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.01);
    }

    100% {
        transform: scale(1.03);
    }
}

.main__header {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    margin-top: 3rem;

}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.main__header-left {
    margin-top: -3rem;
}

.main__header-left h4 {
    margin-bottom: 1rem;
    color: white;
    font-weight: 400;
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 18;
}

.main__header-right {
    display: grid;
    place-items: center;
    position: relative;
    margin: -10%;
}

.main__header-image {
    width: 93%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 10%;
    transform: scale(1);
    transition: transform 0.5s ease-in-out;  
}

.main__header-image:hover {
    transform: scale(1.03);
}




/* ====================== WHAT WE OFFER ====================== */


.whatWeoffer {
    margin-top: 4rem;
}

.whatWeOffer__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}

.whatWeOffer__stuff a {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#bebebe;
}
.whatWeoffer__stuff h4{
    margin-top: 4rem;
}




/* ====================== FAQs ====================== */



.faqs__wrapper {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem 1.5rem;
    align-items: flex-start;
}

.faq {
    background: rgb(170, 170, 170);
    padding: 2rem;
    border-radius: 1rem;
    cursor: pointer;
    transition: var(--transition);
}

.faq::before {
    transition: var(--transition);
}

.faq:hover {
    box-shadow: 0 1rem 1rem rgba(0, 0, 0,0.4);
}

.faq div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq__icon {
    background: transparent;
    color: white;
    font-size: 1.5rem;
}

.faq p {
    text-shadow: none;
    margin-top: 1.5rem;
    color: black;

}




/* ====================== Footer ====================== */

footer {

    background: #FFC83D;
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: white;
}

.footer__container {
    display:grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:0.7rem;
}

.footer__container article p {
    margin-top: 0.5rem;
    text-shadow: none;
    color: black;
    font-family: cursive;
    font-size: 18;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__socials {
    margin-top: 1.5rem;
    display:flex;
    gap:1rem;
    align-items:center;
}

.footer__socials a {
    background: black;
    padding: 0.4rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer__socials a:hover {
    border-color: white;
    background-color: transparent;
} 

.footer__copyright {
    color: white;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid #817e7e;
    margin-top: 0.4rem;

}


/* MEDIA QUERIES (Medium Screens) */

@media screen and (max-width:1024px) {
/*Main Heade*/
    .main__header {
        height: fit-content;
        padding: 10rem 2;
     

    }
    .main__header-container {
        gap: auto;
    }


/*What we offer*/

    .whatWeOffer__wrapper {
        grid-template-columns: 1fr;
    }

    .faqs__wrapper {
        grid-template-columns: 1fr;
    }

    /*Footer*/

    .footer__container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
}



@media screen and (max-width:600px) {
/*Main Header*/

.main__header {
    margin-top: 0;
    padding: 12rem 0 0;
    height: 100vh;
}

.main__header-image {
    display: none;
}

.main__header-container {
    grid-template-columns: 1fr;
}

/*Whaw We Offer*/

.whatWeOffer__wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 3rem;
}

/*FAQS*/

.faqs__wrapper {
    gap: 1rem;
    margin-top:3rem;
}

/*Footer*/

footer {
    margin-top: 7rem;
}

.footer__container {
    grid-template-columns: 1fr;
    gap:3rem;
    margin-top: -4rem;
}

.footer__container article {
    align-items: center;
}

.footer__container article p {
    text-align: center;
}

}